<!-- 公众号-->
<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box">
                <div class="share-desc">
                    <div class="activity">
                        <h2>关注《益享优福》电影福利官方公众号：</h2>
                        <ol>
                            <li>1、为您提供最优惠的电影订票服务，</li>
                            <li>2、获取最新电影咨询</li>
                            <li>3、及时跟踪订单最新状态</li>
                        </ol>
                    </div>
                </div>
                <!-- <img :src="sharePicUrl" style="width: 80%;"> -->
                <div class="share-pic-wrap">
                    <img :src="gzhImg" width="72%" style="border-radius: 10px;" />
                    <br/>
                    <van-button round size="small" type="primary" style="margin-top: 15px;padding: 0 50px;" @click="$goPage('index')">去购票</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            gzhImg: require('@/assets/gzh.jpg')
        }
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	position: relative;
	background-color: #fff;
	.fill-box {
		.share-desc {
			padding: 10px;
			background-color: #fff;
			line-height: 1.8;
		}

		.share-pic-wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
		}
	}
	.activity {
		padding: 8px;
	}

	.activity h2 {
		color: #333;
		font-size: 16px;
		margin: 0;
		margin-bottom: 5px;
	}

	.activity p {
		color: #333;
		font-size: 12px;
		margin-bottom: 3px;
	}

	.activity ol {
		color: #333;
		font-size: 12px;
		margin-left: 10px;
	}

	.activity ol li {
		margin-bottom: 3px;
	}
}

.overlay-share {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.share-pic-tips {
	font-size: 20px;
	color: #efefef;
	margin-top: 8px;
}
</style>
